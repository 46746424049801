<template>
  <div class="CEESAR-PortalGroup CEESAR-PortalGroup-Hidden">
    <div class="CEESAR-PortalGroupHeader">
      <MaterialDesign-Icon v-if="icon != undefined" :icon="icon" size="24"/>
      <div class="CEESAR-PortalGroupLabel">
        {{label}}
      </div>
    </div>
    <div class="CEESAR-PortalGroupContent">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
    name:"CEESAR-PortalGroup",
    props:{
        label:{type: String, required: true},
        icon: {type: String, default: undefined}
    }
}
</script>

<style>

</style>